
.fs-content{
  display: flex;
  align-items: center;
  justify-content: space-between;
  display: grid;
  grid-template-columns: repeat(3,1fr);
  gap: 20px;
  width: 100%;
}

.fs-card{
  background-color: white;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.07);
  padding:16px 16px;
  border-radius: 4px;
  display: flex;
}

.fs-title{
  font-size: 25px;
  margin-bottom: 8px;
  font-weight: 500;
}

.fs-subtitle{
  font-size: 14px;
}