.ms-list {
  padding: 10px 6px;
  cursor: pointer;
  font-size: .90rem;
}
.ms-list:hover {
 background-color: #eee;
}

.ms-list__selected {
  background-color: #ffebe8;
  position: relative;
}

.ms-list__selected::after {
  position: absolute;
  display: inline-block;
  content: '\27A4';
  right: 8px;
}

.ms-row {
  cursor: pointer;
}

.ms-row.disabled {
  color: #6f6f6f;
  pointer-events: none;
}

.ms-row:hover {
  background-color: #eee;
}

.ms-list-col {
  width: 100px;
}


@media (max-width: 1140px) {
  .ms-list-col {
    width: 80px;
  }
}

@media (min-width: 1366px) and (max-width: 1800px) {
  .ms-list-col {
    width: 120px;
  }
}

@media (min-width: 1800px) {
  .ms-list-col {
    width: 150px;
  }
}

