.ptd-item {
  display: grid;
  grid-template-columns: 40% 30% 30%;
  padding: 12px 0 ;
  border-bottom: 2px solid #f4f4f4;
}

.ptd-item:last-child {
  border-bottom: none;
}

.ptd-item:first-child {
  border-bottom: 2px solid #f4f4f4; 
}
