.header-widget-common {
  font-size: 1.2rem;
  text-align: center;
  margin-bottom: 1rem;
  height: 36px;
  box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.5);
}

.plan-reliability-header {
  height: 54px;
  line-height: 22px;
}

.past-week:focus {
  outline: 0;
}

.past-week {
  display: flex;
}

.past-week-button {
  cursor: pointer;
  outline: 0;
}

.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version; currently
                                  supported by Chrome and Opera */
}

.arrow-down-weekly {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid #2e3e46;
  display: flex;
  margin: 4px 3px;
}

.tab-text-weekly {
  font-size: 0.85rem;
  color: #2e3e46;
}

.button-weekly {
  width: 90px;
  height: 28px;
  /* border-radius: 4px; */
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1px;
  outline: 0;
}

.tab-container-weekly {
  background-color: #ffffff;
}

.tabs-weekly {
  display: flex;
  margin-left: 2rem;
}

.link-weekly {
  text-decoration: none;
}

.current-week-label {
  color: #2e3e46;
  padding: 14px 2rem 0px;
  font-size: 0.875rem;
}

.dates-week-label {
  font-weight: bold;
}