.select-box-container {
  width: 340px;
  font-size: 0.75rem;
}

.top-bar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.shortfall-container {
  width: 27%;
  display: flex;
  align-items: center;
  font-size: 1rem;
  padding-right: 12px;
}

.graph-container {
  width: 40%;
}

.graph-table-container {
  width: 60%;
  margin-right: 4px;
}

.graph-vertical-line {
  height: 336px;
  width: 1px;
  background-color: #aaa;
  display: block;
  margin: 0 20px;
}

.tabs-table {
  display: flex;
  width: 96%;
  padding: 0 2% 2% 0;
}

.tabs-button {
  font-size: 0.75rem;
  width: 18%;
  height: 18px;
  padding: 5px;
  margin-left: -1px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 1px #979797;
  cursor: pointer;
  outline: 0;
}

.tabs-button:hover {
  background-color: #e6e6e6;
}

.table-container {
  display: flex;
  flex-flow: column;
  height: 100%;
  width: 100%;
}

.thead-container {
  flex: 0 0 auto;
  width: calc(100% - 0.9em);
  display: table;
  table-layout: fixed;
}

.tbody-container {
  flex: 1 1 auto;
  display: block;
  overflow-y: scroll;
  height: 250px;
}

.tbody-tr-container {
  width: 100%;
  display: table;
  table-layout: fixed;
}

.header {
  cursor: pointer;
}

.header:hover {
  text-decoration: underline;
}