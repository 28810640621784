.btn-container {
  display: flex;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  height: 32px;
  letter-spacing: 0.5px;
  cursor: pointer;
}

.tab-radio{
  display: none;
}

.tab-group{
  display: flex;
  align-items: center;
  gap: 24px;
}

.tab-label{
  border-bottom: 2px solid transparent;
  padding-bottom: 8px;
  font-weight: 500;
}

.tab-radio:checked + .tab-label{
  border-bottom-color: #E9624A;
}

