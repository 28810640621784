.flow-container{
  padding: 0 2rem;
}

.flow-content{
  padding: 2rem 0;
}

.flow-tabs{
  padding-top: 40px;
}

.wigt-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 14px;
  padding: 16px;
  border-radius: 6px;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.07);
  background-color: white;
  margin-top: 24px;
}

.wigt-header {
  font-size: 16px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  font-weight: 500;
  justify-content: center;
}

.tbl-content{
  width: 100%;
}

.tbl-header{
  font-size: 10px;
  color: #727272;
  padding: 0 10px;
}

.tbl-activityName {
  font-weight: 500;
  margin-bottom: 4px;
}

.tbl-parentChain {
  font-size: 11px;
}

.tbl-items {
  padding: 0 10px;
}

.tbl-redLabel {
  color: red;
  padding: 0 10px;
}

.tbl-nodata {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #727272;
}

.anchorTag {
  color: #2e3e46;
  text-decoration: none;
}

.anchorTag:hover {
  text-decoration: underline;
}


