.vertical-line {
  width: 5px;
  background-color: rgb(170, 170, 170);
  display: block;
  /* margin-right: 20px; */
  margin-left: 8px;
}

.horizontal-line {
  display: block;
  position: absolute;
  border: 1px solid rgb(170, 170, 170);
  left: 12px;
  width: 32px;
}