.project-status-widget {
  width: 640px;
  margin: 0 auto;
  position: relative;
  height: 100px;
  font-size: 0.85rem;
  display: flex;
  justify-content: space-between;
}
.project-status-widget .status-field {
  height: 80px;
  width: 80px;
  border-radius: 50px;
  border: 1px solid #fff;
  position: absolute;
  background-color: #fff;
  padding: 1px;
  box-sizing: border-box;
}
.project-status-widget .status-field.dates {
  right: 0px;
}
.project-status-widget .status-field.progress {
  /* right: 52px; */
}
.project-status-widget .status-field.status {
  left: 26px;
}
.project-status-widget .status-field.status .status-text {
  position: relative;
  top: 32px;
  font-weight: bold;
  text-align: center;
  font-size: 0.8rem;
}
.project-status-widget .status-field .top,
.project-status-widget .status-field .bottom {
  height: 50%;
  position: relative;
}
.project-status-widget .status-field .bottom {
  background-color: #f5f5f5;
  border-radius: 0 0 38px 38px;
}
.project-status-widget .status-field .content {
  /* position: absolute; */
  position: relative;
  top: 6px;
  left: 0px;
  width: 80px;
  text-align: center;
}
.project-status-widget .h-separator {
  width: 130px;
  border-top: 1px dotted #aaa;
  /* position: absolute; */
  position: relative;
  top: 40px;
}
.project-status-widget .h-separator .separation-text {
  position: absolute;
  padding-top: 4px;
}
.project-status-widget .h-separator .separation-text.top {
  top: -24px;
}
.project-status-widget .h-separator.right {
  right: 0px;
}
.project-status-widget .h-separator.right .separation-text {
  right: 0px;
}
.project-status-widget .v-separator {
  position: absolute;
  top: 42px;
  left: 0px;
}
.project-status-widget .v-separator > .left,
.project-status-widget .v-separator > .right {
  position: absolute;
}
.project-status-widget .v-separator > .left {
  left: -85px;
}
.project-status-widget .v-separator > .right {
  left: 156px;
  width: 150px;
}

.project-status-widget .arrow {
  position: relative;
}
.project-status-widget .arrow .arrow-head {
  display: inline-block;
  border-right: 2px solid #aaa;
  border-bottom: 2px solid #aaa;
  width: 5px;
  height: 5px;
}
.project-status-widget .arrow.left .arrow-head {
  transform: rotate(135deg);
}
.project-status-widget .arrow.right .arrow-head {
  transform: rotate(-45deg);
}
.project-status-widget .arrow .arrow-line {
  position: absolute;
  top: 7px;
  width: 30px;
  border-top: 1px solid #aaa;
}
.project-status-widget .arrow.right .arrow-line {
  right: 0px;
}

.project-status-widget .float-r {
  float: right;
}
.project-status-widget .float-l {
  float: left;
}

.project-status-widget .status-field {
  height: 100px;
  width: 100px;
  border-radius: 60px;
}
.project-status-widget .status-field .content {
  width: 100px;
  top: 8px;
}
.project-status-widget .status-field .bottom {
  border-radius: 0 0 46px 46px;
}
.project-status-widget .h-separator {
  top: 50px;
  width: 150px;
}
.project-status-widget .status-field.status .status-text {
  font-size: 0.9rem;
  top: 38px;
}

.status-block {
  position: relative;
  width: 152px;
}
.status-block.dates {
  width: 158px;
}
.status-block.dates .h-separator {
  width: 156px;
}
.status-block.status {
  margin-left: 8px;
}

.weekInfo {
  position: absolute;
  top: 46px;
  left: 28px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
