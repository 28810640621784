.underline-hover:hover {
  text-decoration: underline;
}
.header {
  cursor: pointer;
}

.header:hover {
  text-decoration: underline;
}

.deletedGrey {
  color: #6f6f6f;
  pointerEvents: none;
  cursor: default;
}
/* .table-container {
  display: flex;
  flex-flow: column;
  height: 100%;
  width: 100%;
}
.tablehead-container {
  flex: 0 0 auto;
  width: calc(100% - 0.9em);
  display: table;
  table-layout: fixed;
}
.tablebody-container {
  flex: 1 1 auto;
  display: block;
  overflow-y: scroll;
  max-height: 320px;
} */

.grid-container {
  display: grid;
	grid-template-columns: 3fr 0.4fr 0.5fr 0.6fr 1fr 1fr 1fr;
	max-height:350px;
  overflow:auto;
  font-size: 0.9rem;
}
.grid-container .header {
  padding: 0.4rem 0.4rem;
  line-height: 1.4;
  font-weight: bold;
  vertical-align: middle;
  position:sticky;
  top:0;
  cursor: pointer;
  background-color: #f7f9fc;
}
.grid-container .header:hover {
  text-decoration: underline;
}

.grid-container .cell {
  padding: 0.4rem 0.4rem;
  line-height: 1.4;

}
.grid-container .cell.odd-row {
  background-color:#f7f9fc;
}
