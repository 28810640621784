@font-face {
  font-family: 'plando-font';
  src: url(/static/media/Ailerons-Typeface.c7d5266a.otf) format('truetype');
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  background-color: #f5f5f5;
}

b {
  font-weight: bold;
}

a {
  cursor: pointer;
}

table {
  font-size: 0.9rem;
}

table th {
  font-weight: bold;
  vertical-align: middle;
}

table tbody td {
  font-size: 0.85rem;
}

table td,
table th {
  padding: 0.4rem 0.4rem;
  line-height: 1.4;
}

table tr:nth-child(even) {
  background-color: #f7f9fc;
}

table thead tr {
  background-color: #f7f9fc;
}

.widget-container {
  padding: 1rem;
  padding-top: 1rem;
}
.widget {
  display: inline-block;
  width: 48%;
  margin-bottom: 2%;
  margin-left: 1%;
  margin-right: 1%;
  vertical-align: top;
}
@media (max-width: 800px) {
  .widget {
    width: 100%;
  }
}

td > button {
  border: 0;
  font-size: 0.85rem;
  padding: 0;
  outline: 0;
  text-align: left;
}
