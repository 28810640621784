.toggle-button {
  display: flex;
}
.toggle-button__item {
    font-size: 0.9rem;
    padding: 0.5rem 0.8rem;
    color: #000000;
    background-color: #ffffff;
    cursor: pointer;
    border: 1px solid #cccccc;
    margin-left: -1;
}

.toggle-button__item:hover {
  background-color: #e6e6e6;
}
.toggle-button__item:active {
  box-shadow: inset 0 0.2rem 0.6rem rgba(0, 0, 0, .125);
  background-color: #e6e6e6;
}
.toggle-button__item:focus {
  outline: 0;
}

.toggle-button__item--active {
  box-shadow: inset 0 0.2rem 0.6rem rgba(0,0,0,.125);
  background-color: #e6e6e6;
}
.toggle-button__item--first {
  border-top-left-radius: 3;
  border-bottom-left-radius: 3;
}
.toggle-button__item--last {
  border-top-right-radius: 3;
  border-bottom-right-radius: 3;
}