.pd-item {
  display: grid;
  grid-template-columns: 40% 30% 10% 20%;
  padding: 12px 0 ;
  border-bottom: 2px solid #f4f4f4;
}

.pd-item:first-child {
  border-bottom: 2px solid #f4f4f4;
}

.pd-item:last-child {
  border-bottom: none;
}