.container-commitment-reliability {
  width: 100%;
  display: flex;
  padding: 10px;
  height: 294px;
}

.cpr-container-commitment-reliability {
  width: 31%;
}

.other-container-commitment-reliability {
  display: flex;
  flex-direction: column;
  width: 23%;
}

.legend-box-commitment-reliability {
	height: 10px;
	width: 10px;
	display: inline-block;
}

.legend-commitment-reliability {
  font-size: 0.85rem;
  padding: 0 10px;
  margin-left: 10px;
  margin-top: -15px;
  line-height: 16px;
}