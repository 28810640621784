.c-level__dashboard {

}

.c-level__widge_body {
  margin: 0;
  padding: 0;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  background-color: #f5f8ff;
  display: flex;
}

.c-level__header-widget-common {
  font-size: 1.6rem;
  /* text-align: ; */
  margin-bottom: 1rem;
  height: 36px;
  box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.5);
}

.c-level__widget-container {
  padding: 1rem;
  padding-top: 1rem;
  flex: 1;
}


.c-level__widget {
  padding-top: 2rem;
}

.c-level__section {
  padding: 8px;
  display: flex;
   /* justify-content: center; */
  /* 1282415661880 */
}

.c-level__content-row {
  display: flex;
  justify-content: center;
  flex: 1;
}

.c-level__flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.c-level__table {
  font-size: 0.9rem;
  width: 100%;
  text-align: left;
}

.c-level__table th {
  font-weight: bold;
  vertical-align: middle;
}

.c-level__table tbody td {
  font-size: 0.85rem;
}

.c-level__table td,
.c-level__table th {
  padding: 0.4rem 0.4rem;
  line-height: 1.4;
}

.c-level__table tr:nth-child(even) {
  background-color: #edf4ff;
}

.c-level__table thead tr {
  background-color: #edf4ff;
}

.c-level__marginSmall {
  margin-bottom: 8px;
}

.c-level__marginMedium {
  margin-bottom: 12px;
}

.c-level__marginLarge {
  margin-bottom: 16px;
}

.c-level__icon {
  margin-right: 6px;
}