.dr-item {
  display: grid;
  grid-template-columns: 50% 25% 25%;
  padding: 12px 0 ;
  border-bottom: 2px solid #f4f4f4;
}

.dr-item:last-child {
  border-bottom: none;
}

.dr-item:first-child {
  border-bottom: 2px solid #f4f4f4; 
}
