.tab-text-week-item {
  text-decoration: none;
  margin: 12px 12px 16px;
}

.rectangle-week-item {
  width: 106px;
  height: 106px;
  border: 1px solid #484848;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.rectangle-week-item:hover {
  background-color: #d8d8d8;
  border: 1px solid #d8d8d8;
}

.dates-week-item {
  font-weight: bold;
  font-size: 0.75rem;
  line-height: normal;
  text-align: center;
  color: #484848;
}

.line-week-item {
  width: 70px;
  height: 0px;
  border: solid 1px #707070;
  margin-top: 11px;
}

.week-number-week-item {
  font-size: 1rem;
  color: #484848;
  margin-top: 7px;
}