.underline-hover:hover {
  text-decoration: underline;
}

.delete-grid-container {
  display: grid;
	grid-template-columns: 1.5fr 3fr 1fr 1fr;
	max-height:350px;
  overflow:auto;
  font-size: 0.9rem;
}

.delete-grid-container .header {
  padding: 0.4rem 0.4rem;
  line-height: 1.4;
  font-weight: bold;
  vertical-align: middle;
  position:sticky;
  top:0;
  background-color: #f7f9fc;
}

.delete-grid-container .cell {
  padding: 0.4rem 0.4rem;
  line-height: 1.4;

}
.delete-grid-container .cell.odd-row {
  background-color:#f7f9fc;
}
