.flex-container {
  width: 800px;
  color: #484848;
}

.center-text {
  display: flex;
  justify-content: center;
  align-items: center;
}

.box-size {
  width: 106px;
  height: 53px;
  font-size: 0.85rem;
}

.box-size-text {
  width: 100px;
}

.circle-common {
  border: 1px solid #3777b0;
  font-size: 0.85rem;
  font-weight: normal;
}

.half-circle-top {
  width: 106px;
  height: 53px;
  border-radius: 106px 106px 0 0;
  border-bottom: 0;
}

.half-circle-bottom {
  width: 106px;
  height: 53px;
  border-radius: 0 0 106px 106px;
  border-top: 0;
  background: #3777b0;
  color: #ffffff;
}

.full-circle-hollow {
  width: 100px;
  height: 100px;
  border-radius: 100px;
  flex-direction: column;
}

.type-container {
  font-size: 0.85rem;
}

.horizontal-line-status {
  width: 100%;
  display: block;
  height: 1px;
  border: 0;
  border-top: 2px solid #ccc;
}

/* @media screen and (min-width: 1441px) {
  .horizontal-line-status {
    width: 338px;
  }
  .box-size {
    width: 220px;
  }
} */

.arrow-container {
  flex: 1;
}

.arrow-common {
  margin: 0 10px;
}

.arrow-right {
  width: 0;
  height: 0;
  padding-left: 5px;
  border-left: 10px solid #ccc;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
}

.arrow-left {
  width: 0;
  height: 0;
  padding-right: 5px;
  border-right: 10px solid #ccc;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
}

.status-type {
  font-size: 0.9rem;
}

.status-common {
  margin-top: 10px;
  font-size: 0.85rem;
  font-weight: bold;
}

.dotted-horizontal-line {
  border-top: 1px dotted #3777b0;
}

.dotted-horizontal-line-left {
  margin-left: 20px;
}

.dotted-horizontal-line-right {
  margin-right: 20px;
}