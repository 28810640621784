.saveButton {
  color: #ffffff;
  font-size: 12px;
  background: #0098D2;
  border: 0 rgba(0,0,0,0);
  cursor: pointer;
  padding: 6px 10px;
  border-radius: 3px;
}
.saveButton:hover {
  background-image: linear-gradient(transparent,rgba(0,0,0,.05) 40%,rgba(0,0,0,.1))
}
.saveButton:active {
  box-shadow: 0 0 0 1px rgba(0,0,0,.15) inset,0 0 6px rgba(0,0,0,.2) inset;
  letter-spacing: 0.3px ;
}
.saveButton:focus {
  outline:0;
}
.saveButton[disabled] {
  background-image: none;
  opacity: .4;
  cursor: not-allowed;
}
.flex-sp-bet {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}

.delay-reason {
  border: 1px solid grey;
  padding: 10px 10px;
  width: 400px;
  font-size: 12px;
}

.description-field {
  border-color: #ccc;
  padding: 4px;
  width: 100%;
  height: 14px;
  resize: none;
 }

 .buttonGroup {
  display: flex;
  margin-bottom: 8px;
  margin-top: 8px;
  margin-left: 20px;
}

.rejectButton {
  color: #ffffff;
  font-size: 12px;
  background: #d20000;
  border: 0 rgba(0,0,0,0);
  cursor: pointer;
  padding: 6px 10px;
  border-radius: 3px;
}
.rejectButton:hover {
  background-image: linear-gradient(transparent,rgba(0,0,0,.05) 40%,rgba(0,0,0,.1))
}
.rejectButton:active {
  box-shadow: 0 0 0 1px rgba(0,0,0,.15) inset,0 0 6px rgba(0,0,0,.2) inset;
  letter-spacing: 0.3px ;
}
.rejectButton:focus {
  outline:0;
}

.approveButton {
  color: #ffffff;
  font-size: 12px;
  background: #00d266;
  border: 0 rgba(0,0,0,0);
  cursor: pointer;
  padding: 6px 10px;
  border-radius: 3px;
}
.approveButton:hover {
  background-image: linear-gradient(transparent,rgba(0,0,0,.05) 40%,rgba(0,0,0,.1))
}
.approveButton:active {
  box-shadow: 0 0 0 1px rgba(0,0,0,.15) inset,0 0 6px rgba(0,0,0,.2) inset;
  letter-spacing: 0.3px ;
}
.approveButton:focus {
  outline:0;
}

