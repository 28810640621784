.underline-hover:hover {
  text-decoration: underline;
}
.header {
  cursor: pointer;
}
.header:hover {
  text-decoration: underline;
}
.table-container {
  height: 100%;
  width: 100%;
}
.thead-container {
  flex: 0 0 auto;
  table-layout: fixed;
}
