.cw-item {
  display: grid;
  grid-template-columns: 50% 50%;
  padding: 12px 0 ;
  border-bottom: 2px solid #f4f4f4;
}

.cw-item:last-child {
  border-bottom: none;
}

.cw-item:first-child {
  border-bottom: 2px solid #f4f4f4; 
}
